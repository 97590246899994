/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import/write your custom styles here.
/*  @ Styles from this file will override 'vendors.scss' and Fuse's base styles.
/* ----------------------------------------------------------------------------------------------------- */

@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

.snackActionText {
    .mdc-snackbar__surface {
        @apply bg-gray-900 #{'!important'};
    }

    button {
        @apply text-primary-500 #{'!important'};
    }
}

.text-secondary {
    color: #000 !important;
}

.px-5 {
    padding-left: 1.25rem !important;
    padding-right: 1.25rem !important;
}

mat-label {
    color: #000 !important;
}

.mat-mdc-form-field.mat-form-field-appearance-fill .mat-mdc-text-field-wrapper {    
    border-color: rgb(203 213 225 / var(--tw-border-opacity)) !important;
}


.show-hover>div {
    visibility: hidden;
}
.show-hover:hover>div {
      visibility: visible !important;
}

.hover\:bg-hover-dark:hover {
    background-color: #0003 !important;
}

.theme-default .light .mat-step-header .mat-step-icon-selected, .theme-default .light .mat-step-header .mat-step-icon-state-done, .theme-default .light .mat-step-header .mat-step-icon-state-edit, .theme-default.light .mat-step-header .mat-step-icon-selected, .theme-default.light .mat-step-header .mat-step-icon-state-done, .theme-default.light .mat-step-header .mat-step-icon-state-edit {
    background: var(--fuse-primary-600);
}

.grid-cols-13 {
    grid-template-columns: repeat(13,minmax(0,1fr)) !important;
}

.grid-cols-14 {
    grid-template-columns: repeat(14,minmax(0,1fr)) !important;
}

.grid-cols-15 {
    grid-template-columns: repeat(15,minmax(0,1fr)) !important;
}

.col-span-13 {
    grid-column: span 13 / span 13 !important;
}

@media (min-width: 600px) {
    .sm\:grid-cols-13 {
        grid-template-columns: repeat(13,minmax(0,1fr)) !important;
    }
    .sm\:grid-cols-14 {
        grid-template-columns: repeat(14,minmax(0,1fr)) !important;
    }
    .sm\:grid-cols-15 {
        grid-template-columns: repeat(15,minmax(0,1fr)) !important;
    }
    .sm\:col-span-13 {
        grid-column: span 13 / span 13 !important;
    }
}
